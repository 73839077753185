import { createStore } from 'vuex';

export default createStore({
  state: {
    isLogin: false,
    isLoading: false,
    userInfo: {},
    mainInfo: {},
    bankInfo: {},
    unread_count: {},
    marketPrice: [],
    gameList: [],
    bankList: [],
    phoneCountries: [],
  },
  mutations: {
    set_is_loading(state, payload) {
      state.isLoading = payload;
    },
    set_is_login(state, payload) {
      state.isLogin = payload;
    },
    set_user_info(state, payload) {
      state.userInfo = payload;
    },
    set_main_info(state, payload) {
      state.mainInfo = payload;
    },
    set_bank_info(state, payload) {
      state.bankInfo = payload;
    },
    set_unread_count(state, payload) {
      state.unread_count = payload;
    },
    set_market_price(state, payload) {
      state.marketPrice = payload;
    },
    set_game_list(state, payload) {
      state.gameList = payload;
    },
    set_bank_list(state, payload) {
      state.bankList = payload;
    },
    set_phone_countries(state, payload) {
      state.phoneCountries = payload;
    },
  },
  actions: {},
  modules: {},
});

<template>
  <router-view />
  <div
    v-show="store.state.isLoading"
    class="bg-black bg-opacity-50 w-full h-screen fixed inset-0 z-[10] flex items-center justify-center"
  >
    <van-loading type="spinner" size="40" color="#24B6E4" vertical />
  </div>
  <!-- <a
    href="http://tg58257.lol/index/index/home?visiter_id=&visiter_name=&avatar=&groupid=0&business_id=1"
    target="_blank"
    class="fixed bottom-10 left-6 bg-[#24B6E4] p-3 w-20 h-20 rounded-full shadow flex items-center justify-center text-2xl"
  >
    客服
  </a> -->
  <!-- <img src="@/assets/icons/support.svg" alt="icon" /> -->
</template>
<script setup>
import { useStore } from 'vuex';
import { get_main_info, get_bank_list, get_phone_countries } from '@/api/main';
import { get_unread_count } from '@/api/message';
import { onMounted, watch } from 'vue';
import { connectSocket, get_market_list } from '@/api/market';
import { get_games_list } from '@/api/game';

const store = useStore();
let timer = null;
onMounted(async () => {
  console.log('version: 13');
  await get_main_info();
  await get_bank_list();
  await get_phone_countries();
  await get_games_list();
  await get_market_list();
  connectSocket();
});
watch(
  () => store.state.isLogin,
  (newVal) => {
    if (newVal) {
      get_unread_count();
      timer = setInterval(() => {
        get_unread_count();
      }, 1000 * 15);
    } else {
      clearInterval(timer);
    }
  }
);
</script>

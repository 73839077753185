import axios from '@/utils/axios';
import store from '@/store/index.js';
import { showSuccessToast, showFailToast } from 'vant';
export async function get_note_list(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/note/list', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}
export async function get_note_info(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/note/info', payload);
  if (!data.error) {
    get_unread_count();
    store.commit('set_is_loading', false);
    return data.ret.info;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}
export async function get_unread_count() {
  const data = await axios.post('/api/note/un-read');
  if (!data.error) {
    store.commit('set_unread_count', data.ret.count);
    return data.ret.count;
  } else {
    return false;
  }
}
export async function set_all_read(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/note/read-all', payload);
  if (!data.error) {
    get_unread_count();
    store.commit('set_is_loading', false);
    showSuccessToast('设置成功');
    return true;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}

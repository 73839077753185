import axios from '@/utils/axios';
import store from '@/store/index.js';
import { showFailToast } from 'vant';
import { calculateScale } from '@/utils/transfer-numbers';
var socket = null;

const option = {
  url: store.state.mainInfo.ws
    ? store.state.mainInfo.ws
    : 'wss://www.kline-center.app/socket',
};
export const connectSocket = () => {
  socket = new WebSocket(option.url);
  socket.onopen = function () {
    console.log('websocket connected!!');
    socket.send(
      JSON.stringify({
        event: 'realtime_all',
      })
    );
  };
  socket.onmessage = function (event) {
    const marketPrice = JSON.parse(JSON.stringify(store.state.marketPrice));
    const wsData = JSON.parse(event.data).data;
    if (!Array.isArray(wsData)) return;
    marketPrice.forEach((node) => {
      const wsFilterData = wsData.find(
        (wsNode) => node.symbol === wsNode.symbol
      );
      if (wsFilterData) {
        node.low = wsFilterData.low_price;
        node.high = wsFilterData.high_price;
        node.open = wsFilterData.open_price;
        node.value = wsFilterData.last_price;
        node.volume = wsFilterData.volume;
        node.scale = +calculateScale(
          +wsFilterData.open_price,
          +wsFilterData.last_price
        );
      }
    });
    store.commit('set_market_price', marketPrice || []);
  };
  socket.onerror = function (err) {
    console.log('error', err);
  };
  socket.heart = function () {
    setInterval(() => {
      socket.send(
        JSON.stringify({
          action: 'heart',
        })
      );
    }, 1000);
  };
};
export async function get_market_list() {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/market/binary-list');
  if (!data.error) {
    store.commit('set_market_price', data.ret.data);
    store.commit('set_is_loading', false);
    return true;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}
export async function get_market_info(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/market/info', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.info;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}

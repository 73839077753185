import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store/index.js';
import { get_user_info } from '@/api/main';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/sign',
    name: 'Sign',
    component: () => import('../views/Sign.vue'),
  },
  {
    path: '/market',
    name: 'Market',
    component: () => import('../views/Market.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/trade/:id',
    name: 'Trade',
    component: () => import('../views/Trade.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/message',
    name: 'Message',
    component: () => import('../views/Message.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/game-list',
    name: 'GameList',
    component: () => import('../views/GameList.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/message-content/:id',
    name: 'MessageContent',
    component: () => import('../views/MessageContent.vue'),
    meta: { requireAuth: true },
  },
  {
    path: '/game/:type/:time/:id',
    name: 'Games',
    component: () => import('../views/Games.vue'),
    meta: { requireAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.meta.requireAuth) {
    await get_user_info();
    if (store.state.isLogin) {
      next();
    } else {
      next('/sign');
    }
  } else {
    next();
  }
});

export default router;

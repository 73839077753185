import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/style/tailwind.scss';
import Vant from 'vant';
import { Icon } from 'vant';
import 'vant/lib/index.css';
import Vconsole from 'vconsole';
const app = createApp(App);
if (process.env.NODE_ENV !== 'production') {
  // 测试和开发打开，生产不能打开
  let vConsole = new Vconsole();
  app.use(vConsole);
}
app.use(store).use(Vant).use(Icon).use(router).mount('#app');

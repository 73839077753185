import axios from '@/utils/axios';
import countryCodeAndPhoneCode from '@/utils/countryCodeAndPhoneCode';
import store from '@/store/index.js';
import router from '@/router/index.js';
import { showSuccessToast, showFailToast } from 'vant';

//===================登入===================//
export async function set_sign_in(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/sign/in', payload);
  if (!data.error) {
    store.commit('set_is_login', true);
    showSuccessToast('登入成功');
    await get_user_info();
    router.push('/');
    store.commit('set_is_loading', false);
    return true;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}
//===================注册===================//
export async function set_sign_up(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/sign/up', payload);
  if (!data.error) {
    showSuccessToast('注册成功');
    store.commit('set_is_loading', false);
    return true;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}
//===================注册验证码===================//
export async function get_captcha_img(payload) {
  const data = await axios.post(
    process.env.NODE_ENV === 'production'
      ? '/api/sign/captcha?refresh=1'
      : '/api/sign/captcha?refresh=1',
    payload
  );
  if (!data.error) {
    return data.url;
  } else {
    showFailToast(data.msg);
    return false;
  }
}
//===================登出===================//
export async function set_sign_out(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/sign/out', payload);
  if (!data.error) {
    store.commit('set_is_login', false);
    store.commit('set_is_loading', false);
    store.commit('set_user_info', {});
    showSuccessToast('登出成功');
    router.push('/sign');
    return true;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}

//===================会员资讯===================//
export async function get_user_info(payload) {
  const res = await axios.post('/api/user/info', payload);
  if (!res.error) {
    store.commit('set_is_login', true);
    store.commit('set_user_info', res.ret.info);
    return res.ret.info;
  } else {
    return false;
  }
}

//===================会员资讯===================//
export async function get_bank_info() {
  const data = await axios.post('/api/user/bank-info');
  if (!data.error) {
    store.commit('set_bank_info', data.ret.info);
    return data.ret.info;
  } else {
    showFailToast(data.msg);
    return false;
  }
}

//===================网站资讯 ===================//
export async function get_main_info() {
  const data = await axios.post('/api/main/info');
  if (!data.error) {
    store.commit('set_main_info', data.ret.info);
    return data.ret.info;
  } else {
    showFailToast(data.msg);
    return false;
  }
}
//===================区码列表===================//
export async function get_phone_countries() {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/main/phone-countries-info');
  if (!data.error) {
    data.ret.info.countries.map((e) => {
      const item = countryCodeAndPhoneCode.find(
        (x) => x.country_code === e.iso2
      );
      e.label = `+${e.e164} ${e.iso2}`;
      e.chinese_name = item ? item.chinese_name : null;
    });
    store.commit('set_phone_countries', data.ret.info.countries);
    store.commit('set_is_loading', false);
    return data.ret.info;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}
//===================银行列表===================//
export async function get_bank_list() {
  const data = await axios.post('/api/main/bank-list');
  if (!data.error) {
    store.commit('set_bank_list', data.ret.list);
    return data.ret.list;
  } else {
    showFailToast(data.msg);
    return false;
  }
}
//===================修改密码===================//
export async function set_password_change(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/user/reset-password', payload);
  if (!data.error) {
    showSuccessToast('修改成功\n请重新登入');
    await axios.post('/api/sign/out');
    router.push('/sign');
    store.commit('set_is_login', false);
    store.commit('set_is_loading', false);
    store.commit('set_user_info', {});
    return true;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}
//===================设定银行卡===================//
export async function set_bank_card(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/user/set-bank', payload);
  if (!data.error) {
    get_user_info();
    store.commit('set_is_loading', false);
    return true;
  } else {
    showFailToast(data.msg);
    store.commit('set_is_loading', false);
    return false;
  }
}

//===================会员资讯===================//
export async function get_online_count() {
  const data = await axios.post('/api/main/online-count');
  if (!data.error) {
    return data.ret;
  } else {
    showFailToast(data.msg);
    return false;
  }
}

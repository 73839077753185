export function addSignSymbol (val) {
  if (val < 0) {
    return val
  } else {
    return '+' + val
  }
}

export function calculateScale (openTime, valueNow) {
  if (openTime - valueNow === 0) {
    return 0
  } else if (openTime === 0) {
    return (valueNow - openTime)
  } else {
    return (valueNow - openTime) / openTime
  }
}

export function addSignSymbolAndPercentage (value) {
  if (!Number.isNaN(value)) {
    if (value < 0) {
      return parseFloat((value * 100).toPrecision(2)) + '%'
    } else {
      return '+' + parseFloat((value * 100).toPrecision(2)) + '%'
    }
  }
}

export function toMillion (val) {
// Nine Zeroes for Billions
  return Math.abs(Number(val)) >= 1.0e+9
    ? (Math.abs(Number(val)) / 1.0e+9).toFixed(2) + 'B'
    // Six Zeroes for Millions
    : Math.abs(Number(val)) >= 1.0e+6
      ? (Math.abs(Number(val)) / 1.0e+6).toFixed(2) + 'M'
    // Three Zeroes for Thousands
      : Math.abs(Number(val)) >= 1.0e+3
        ? (Math.abs(Number(val)) / 1.0e+3).toFixed(2) + 'K'
        : Math.abs(Number(val))
}

export function convertRemToPixels (rem) {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize)
}

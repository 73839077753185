import axios from '@/utils/axios';
import store from '@/store/index.js';
import { showSuccessToast, showFailToast } from 'vant';
import { get_user_info } from './main';

//===================游戏列表===================//
export async function get_games_list(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/game/list', payload);
  if (!data.error) {
    store.commit('set_game_list', data.ret.data);
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}

//===================游戏资讯===================//
export async function get_games_info(payload) {
  // store.commit('set_is_loading', true);
  const data = await axios.post('/api/game/info', payload);
  if (!data.error) {
    // store.commit('set_is_loading', false);
    return data.ret.info;
  } else {
    // store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}

//===================近期开奖===================//
export async function get_history_serial(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/game/history-serial', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}

//===================投注记录===================//
export async function get_game_orders(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/game/order-list', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}

//===================投注===================//
export async function set_game_bet(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/game/user-bet', payload);
  if (!data.error && data.ret.bet) {
    showSuccessToast('投注成功');
    get_user_info();
    store.commit('set_is_loading', false);
    return true;
  } else {
    store.commit('set_is_loading', false);
    if (data.msg) {
      showFailToast(data.msg);
    } else {
      showFailToast('投注失败');
    }
    return false;
  }
}
//===================排名分析===================//
export async function get_serial_analyze(payload) {
  store.commit('set_is_loading', true);
  const data = await axios.post('/api/game/serial-analyze', payload);
  if (!data.error) {
    store.commit('set_is_loading', false);
    return data.ret.data;
  } else {
    store.commit('set_is_loading', false);
    showFailToast(data.msg);
    return false;
  }
}
